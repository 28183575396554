/* 表头样式 */
.custom-table .ant-table-thead>tr>th {
    background-color: #1e90ff;
    color: white;
    text-align: center;
    font-size: 18px;
}

/* 表格行样式，调整行高和内边距 */
.custom-table .ant-table-tbody>tr>td {
    text-align: center;
    padding: 6px;
}

/* 表格上margin */
.ant-table-wrapper {
    margin-top: 40px;
}

/* 调整表格行的高度 */
.custom-table .ant-table-row {
    height: 28px;
}

/* 表格的字体大小 */
.custom-table .ant-table-cell {
    font-size: 12px;
}

/* 分组管理弹窗样式优化 */
.ant-modal {
    width: min(80%, 1000px) !important; /* 使用min函数实现自适应 */
    margin: 0 auto !important;
}

.ant-modal-body {
    max-height: 75vh;
    overflow-y: auto;
    padding: 24px !important;
}

/* 分组管理表格样式 */
.ant-modal .ant-table-wrapper .ant-table {
    table-layout: auto !important; /* 改为auto以实现自适应 */
    width: 100% !important;
}

.ant-modal .ant-table-wrapper .ant-table-thead>tr>th {
    background-color: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    text-align: left !important;
    font-size: 14px !important;
    padding: 12px 16px !important;
    white-space: nowrap !important; /* 表头不换行 */
}

.ant-modal .ant-table-wrapper .ant-table-tbody>tr>td {
    text-align: left !important;
    padding: 12px 16px !important;
    font-size: 14px !important;
    vertical-align: top !important;
}

/* 列宽度调整 - 使用相对宽度 */
.ant-modal .ant-table-wrapper .ant-table-cell:first-child {
    width: 15% !important;
    min-width: 100px !important;
}

.ant-modal .ant-table-wrapper .ant-table-cell:nth-child(2) {
    width: 70% !important; /* 地址列占据主要空间 */
    min-width: 250px !important;
}

.ant-modal .ant-table-wrapper .ant-table-cell:last-child {
    width: 15% !important;
    min-width: 80px !important;
}

/* 地址文本处理 */
.ant-modal .ant-table-wrapper .ant-table-cell {
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
    line-height: 1.6 !important;
}

/* 输入框样式优化 */
.ant-modal .ant-input {
    display: block !important;
    width: 100% !important;
    word-break: break-all !important;
    white-space: pre-wrap !important;
    height: auto !important;
    min-height: 32px !important;
    line-height: 1.6 !important;
    padding: 8px 12px !important;
    resize: vertical !important;
    margin-bottom: 8px !important;
    font-family: monospace !important;
}

/* 地址输入区域容器样式 */
.ant-modal .ant-form-item-control-input-content {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
}

/* 表单项样式 */
.ant-modal .ant-form-item {
    margin-bottom: 16px !important;
    width: 100% !important;
}

/* 按钮样式 */
.ant-modal .ant-btn {
    margin-left: 8px !important;
}

/* 表格行悬停效果 */
.ant-modal .ant-table-tbody > tr:hover > td {
    background-color: #f5f5f5 !important;
}

/* 删除按钮样式 */
.ant-modal .ant-table-wrapper .ant-btn-link {
    padding: 4px 8px !important;
    height: auto !important;
    white-space: nowrap !important;
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
    .ant-modal {
        width: 95% !important;
    }
    
    .ant-modal .ant-table-wrapper .ant-table-cell:first-child {
        width: 20% !important;
    }
    
    .ant-modal .ant-table-wrapper .ant-table-cell:nth-child(2) {
        width: 60% !important;
    }
    
    .ant-modal .ant-table-wrapper .ant-table-cell:last-child {
        width: 20% !important;
    }
}